<template>
  <div class="worker-info-wrapper">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="worker-info_row">
          <label>店铺：</label>
          <span>{{info.shopName}}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="worker-info_row">
          <label>店铺号：</label>
          <span>{{info.shopNo}}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="worker-info_row">
          <label>工单号：</label>
          <span>{{info.orderNo}}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="worker-info_row">
          <label>状态：</label>
          <span class="detail-status" :class="info.status==2||info.status==7?'reject':''">{{info.statusName}}</span>
        </div>
      </el-col>

    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="worker-info_row">
          <label>报修人：</label>
          <span>{{info.username}}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="worker-info_row">
          <label>联系电话：</label>
          <span>{{info.mobile}}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="worker-info_row">
          <label>报修时间：</label>
          <span>{{info.createTime}}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="worker-info_row">
          <label>报修区域：</label>
          <span>{{info.areaName}}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="worker-info_row">
          <label>故障类型：</label>
          <span>{{info.typeName}}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="worker-info_row">
          <label>详细地址：</label>
          <span>{{info.address}}</span>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="worker-info_row">
          <label>故障描述：</label>
          <span>{{info.description}}</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "order-info",
  props:{
    info:{
      type:Object,
      default:{

      }
    }
  },
  data(){
    return {
      statusMap:null
    }
  },
  created(){
    this.getStatus()
  },
  methods:{
    getStatus(){
      this.statusMap = new Map()
      this.$api.property.getAllStatus({}).then(res=>{
        res.data.list.forEach(item=>{
          this.statusMap.set(item.value,item.name)
        })
      })
    },

  }
}
</script>

<style scoped lang="scss">
.worker-info_row{
  >label{
    width: 90px;
    text-align: right;
  }
}
.detail-status{
  color: $subjectColor;
}
.detail-status.reject{
  color:#DA001B !important;
}
</style>