<template>
  <div class="order-detail-content clearfix">
    <div class="main-content">
      <div class="left-content">
        <div class="content-block detail-maininfo">
          <!--    基本信息      -->
          <order-info :info="detailObj"></order-info>
        </div>
        <div  class="content-block  form-content">
          <div class="form-group_item width100">
            <label class="nowrap">照片/视频：</label>
            <resources :source="detailObj.descriptionResources"></resources>
          </div>

          <el-divider v-if="detailObj.status>4"></el-divider>
          <!--    维修人员信息      -->
          <div class="form-content repairer-info" v-if="detailObj.status>4">
            <div class="form-group">
              <div class="form-group_item">
                <label>维修人员：</label>
                <span>{{detailObj.repairUserName}}</span>
              </div>
              <div class="form-group_item">
                <label>联系电话：</label>
                <span>{{detailObj.repairUserMobile}}</span>
              </div>
              <!--    有偿/无偿          -->
              <template v-if="detailObj.status>5">
                <div class="form-group_item width100">
                  <label>有偿服务：</label>
                  <span>{{detailObj.isPaid?'是':'否'}}</span>
                </div>
                <div class="form-group_item" v-if="detailObj.isPaid">
                  <label>维修金额：</label>
                  <span class="fontsize20 mgh10">{{detailObj.price/100}}</span>
                  <span>元</span>
                  <span class="done" v-if="detailObj.status>=8">已缴费</span>
                </div>
                <div class="form-group_item description repair-description">
                  <div>{{detailObj.paidDescription||'无'}}</div>
                </div>
              </template>
            </div>
          </div>

          <!--    处理方法      -->
          <template v-if="detailObj.status>8">
            <el-divider></el-divider>
            <div class="form-content">
              <div class="form-group_item width100">
                <label>处理方法：</label>
                <div class="description">{{detailObj.repairDescription||'无'}}</div>
              </div>
              <div class="form-group_item width100">
                <label>图片/视频：</label>
                <resources :source="detailObj.repairResources"></resources>
              </div>
            </div>
          </template>

          <!--     评价         -->
          <template v-if="detailObj.status>10">
            <el-divider></el-divider>
            <div class="block-tag">评价</div>
            <div class="form-content rate">
              <div class="rate_item">
                <label>服务态度</label>
                <el-rate v-model="detailObj.scoreAttitude+3"  disabled text-color="#ff9900"></el-rate>
              </div>
              <div class="rate_item">
                <label>解决速度</label>
                <el-rate v-model="detailObj.scoreSolveSpeed+3" disabled text-color="#ff9900"></el-rate>
              </div>
              <div class="rate_item">
                <label>解决质量</label>
                <el-rate v-model="detailObj.scoreSolveQuality+3"  disabled text-color="#ff9900"></el-rate>
              </div>
              <div class="rate_item">
                <label>描述</label>
                <div class="description">{{detailObj.evaluateDescription||'无'}}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="diliver"></div>
      <div class="right-content">
        <h3>工单流程</h3>
        <order-flow :repairNodes="detailObj.repairNodes"></order-flow>
      </div>
    </div>
  </div>
</template>

<script>
import OrderInfo from "./order-info";
import OrderFlow from "./order-flow";
import Resources from "./resources";
export default {
  name: "property-order-detail",
  components: {Resources, OrderFlow, OrderInfo},
  props:['id'],
  data(){
    return{
      detailObj:{}
    }
  },
  mounted(){
    this.getDetail(this.id);
  },
  methods:{
    getDetail(id){
      this.$api.property.getWorkOrderDetail({id}).then(res=>{
        this.detailObj = Object.assign({},res.data,this.detailObj)
        this.getRepairerInfo(this.detailObj.createBy);
      })
    },
    // 根据报修人编号查询报修人的详细信息
    getRepairerInfo(id){
      this.$api.property.getShopByUserId({id}).then(res=>{
        this.detailObj = Object.assign({},{
          idCard:res.data.idCard,
          mobile:res.data.mobile,
          shopName:res.data.shopName,
          shopNo:res.data.shopNo,
          username:res.data.username,
        },this.detailObj)
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/pages/propertyModule/style/detail";
</style>